import { lazy } from 'react';
import Loadable from 'component/Loadable';

const Authorize = Loadable(lazy(() => import('views/Oauth2/authorize')));
const Login = Loadable(lazy(() => import('views/Oauth2/login')));
const Auth = Loadable(lazy(() => import('views/Oauth2/auth')));

const Oauth2Routes = {
  path: 'oauth',
  children: [
    {
      path: 'authorize',
      element: <Authorize />
    },
    {
      path: 'login',
      element: <Login />
    },
    {
      path: 'auth',
      element: <Auth />
    }
  ]
};

export default Oauth2Routes;
